import React from "react";
import { graphql } from "gatsby";
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";
import images from "../components/images";
import MixPostGrid from "../components/mix-post-grid";
import SharePopupModel from "../components/share-popup-model";
import { Helmet } from "react-helmet";
export default ({ data }) => {
  let color = [
    { backgroundColor: "#ea4b4b" },
    { backgroundColor: "#5d5d5d" },
    { backgroundColor: "#034389" },
    { backgroundColor: "#0091b4" },
  ];

  let img = [images.redDottedHalfCircle, images.triangle];

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Blogs list - Food Safety Mantra</title>
        <meta name="description" content="all blogs of - Food Safety Mantra" />

        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Blogs list - Food Safety Mantra" />
        <meta
          property="og:description"
          content="all blogs of - Food Safety Mantra"
        />
        <meta
          property="og:url"
          content="https://www.foodsafetymantra.com/blogs/"
        />
        <meta property="og:site_name" content="Food Safety Mantra" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="admin" />
        <meta name="twitter:label2" content="Est. reading time" />
        <meta name="twitter:data2" content="14 minutes" />
        <script
          type="application/ld+json"
          className="yoast-schema-graph"
        >{`{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://www.foodsafetymantra.com/#website","url":"https://www.foodsafetymantra.com/","name":"Food Safety Mantra","description":"Food Safety Mantra is the perfect place where our readers can find resources regarding food safety explained in a simplified manner","potentialAction":[{"@type":"SearchAction","target":"https://www.foodsafetymantra.com/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://www.foodsafetymantra.com/blogs/","url":"https://www.foodsafetymantra.com/blogs/","name":"Blogs list - Food Safety Mantra","isPartOf":{"@id":"https://www.foodsafetymantra.com/#website"},"datePublished":"2020-04-10T17:52:00+00:00","dateModified":"2020-04-15T07:33:19+00:00","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.foodsafetymantra.com/blogs/"]}]}]}`}</script>
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Food Safety Mantra &raquo; Feed"
          href="https://www.foodsafetymantra.com/feed/"
        />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Food Safety Mantra  &raquo; Comments Feed"
          href="https://www.foodsafetymantra.com/comments/feed/"
        />
      </Helmet>

      <Header />
      <Menu />

      <div className="section-title pt-5">
        <h1 className="section-title-heading fsm-theme-heading">All Posts</h1>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div key={0}>
                <MixPostGrid
                  data={{
                    newsArr: data.allWordpressPost.edges,
                    regulatoryArr: data.allWordpressWpRegulatoryUpdates.edges,
                    insightArr: data.allWordpressWpRegulatoryInsight.edges,
                  }}
                  key={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <SharePopupModel name={"shareModal"} />
    </>
  );
};

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          link
          date(formatString: "MM-DD-YYYY")
          type
          categories {
            name
            link
            wordpress_id
          }
          licenses_and_approvals
          author {
            name
          }
          compliance_services
        }
      }
    }
    allWordpressWpRegulatoryInsight(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          link
          date(formatString: "MM-DD-YYYY")
          type
          categories {
            name
            link
            wordpress_id
          }
          licenses_and_approvals
          compliance_services
          content
        }
      }
    }
    allWordpressWpRegulatoryUpdates(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          link
          date(formatString: "MM-DD-YYYY")
          type
          categories {
            name
            link
            wordpress_id
          }
          licenses_and_approvals
          compliance_services

          acf {
            date_of_implementation
            draft_or_gazette
            impacted_areas_business
            impacted_people
            main_highlights
            reference
            reference_regulation
            summary
          }
          author {
            name
          }
        }
      }
    }
  }
`;
